import { Box, Grid, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import tick from '../assets/services/tick.svg';

export default function ImageWithText({
  id,
  title,
  description,
  img,
  services,
  reverse = false,
}) {
  return (
    <Grid
      id={id}
      container
      direction={reverse ? 'row-reverse' : 'row'}
      py={isMobile ? 4 : 12}
      sx={{ justifyContent: 'space-between', alignItems: 'center' }}
    >
      <Grid item container lg={6}>
        <Grid item lg={12}>
          <Typography variant="h2">{title}</Typography>
        </Grid>
        <Grid item lg={12} sx={{ mt: 4 }}>
          <Typography variant="body">{description}</Typography>
          <Grid item sx={{ mt: 2 }}>
            {services.map((service, idx) => (
              <Grid
                key={idx}
                container
                wrap="nowrap"
                spacing={2}
                sx={{ alignItems: 'center', marginTop: '0.01em' }}
              >
                <Grid item>
                  <Box component="img" src={tick} />
                </Grid>
                <Grid item>
                  <Typography variant="body" sx={{ fontWeight: 700 }}>
                    {service}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        lg={5}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          component="img"
          src={img}
          sx={{
            mt: 4,
            width: '85%',
            height: 'auto',
          }}
        />
      </Grid>
    </Grid>
  );
}
