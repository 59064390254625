import { Card, Box, Typography, Button } from '@mui/material';
import { isMobile } from 'react-device-detect';
import useLanguage from '../providers/useLanguage';
import { scrollTo } from '../util/scrollTo';

export default function ServiceCard({ to, svg, text, description }) {
  const {
    language: { services: content },
  } = useLanguage();

  const handleClick = (id) => {
    scrollTo({ id });
  };
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        width: isMobile ? '100%' : '235px',
        px: 2,
        py: 4,
        gap: 2,
        boxShadow: '0px 58px 115px rgb(0 6 70 / 4%)',
        borderRadius: '12px',
        ':hover': {
          boxShadow: '0px 38px 115px rgb(0 6 70 / 8%);',
        },
      }}
    >
      <Box>
        <img src={svg} alt="" height="50" width="50" />
      </Box>
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          fontFamily: 'unset',
          fontSize: '16px',
          color: '#000646',
        }}
      >
        {text}
      </Typography>
      <Typography variant="body" sx={{ fontSize: '14px' }}>
        {description}
      </Typography>
      <Button
        onClick={() => handleClick(to)}
        color="primary"
        sx={{
          borderRadius: '30px',
          textTransform: 'none',
          fontFamily: 'unset',
        }}
      >
        {content.button}
      </Button>
    </Card>
  );
}
