import { Typography } from '@mui/material';

const ReactRotatingText = require('react-rotating-text');

export default function RotatingText({ items, isMobile = false }) {
  return (
    <Typography
      variant="h1"
      sx={{
        fontWeight: 700,
        background: 'linear-gradient(#23e5af, #23e5af)',
        backgroundPosition: '0 calc(100% - 5px)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: isMobile ? '100% 10px' : '100% 15px',
        display: 'inline',
        opacity: '1',
      }}
    >
      <ReactRotatingText items={items} />
    </Typography>
  );
}
