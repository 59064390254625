import { Box, Container, Typography } from "@mui/material";
import notFound from '../assets/etc/notFound.gif';
import useLanguage from "../providers/useLanguage";

const NotFound = () => {
  const { language } = useLanguage();

  return (
    <Box sx={{
      display: 'flex',
      py: 12,
      justifyContent: 'center',
      textAlign: 'center',
      flexDirection: 'column'
    }}>
      <Typography variant="h1">
        404
      </Typography>
      <Container maxWidth="md">
        <Box component="img" src={notFound} sx={{ width: '100%' }} />
      </Container>
      <Typography variant="h4">
        {language.notFound}
      </Typography>
    </Box>
  )
}

export default NotFound;