import { Box, Typography, Container } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import useLanguage from '../providers/useLanguage';
import NavLinkButton from '../components/NavLinkButton';
import logoIcon from '../assets/logo-icon.png';
import bg from '../assets/country/bg.png';
import uk from '../assets/country/uk.png';
import { scrollTo } from '../util/scrollTo';

const Footer = () => {
  const {
    setLanguage,
    language: { footer: footerContent, navigation: navigationContent },
  } = useLanguage();

  const pages = [
    {
      title: navigationContent.home,
      to: 'start',
    },
    {
      title: navigationContent.services,
      to: 'services',
    },
    {
      title: navigationContent.aboutMe,
      to: 'about-me',
    },
    {
      title: navigationContent.reviews,
      to: 'reviews',
    },
    {
      title: navigationContent.contact,
      to: 'contact',
    },
  ];

  return (
    <Box
      sx={{
        py: 12,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 8,
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              component="img"
              src={logoIcon}
              sx={{ width: '75px', height: '75px', mb: 3 }}
            />

            <Typography variant="logo">{footerContent.typography1}</Typography>
            <Typography variant="logo" sx={{ color: '#6062ff', mb: 4 }}>
              {footerContent.typography2}
            </Typography>
            <div>
              {pages.map(({ title, to }, idx) => (
                <NavLinkButton
                  handleClick={() => scrollTo({ id: to })}
                  key={idx}
                  title={title}
                  to={to}
                  idx={idx}
                  isFooter
                />
              ))}
            </div>
            <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
              <Box
                onClick={() => setLanguage('bg')}
                component="img"
                src={bg}
                sx={{
                  width: '28px',
                  height: '20px',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
              />
              <Box
                onClick={() => setLanguage('en')}
                component="img"
                src={uk}
                sx={{
                  width: '28px',
                  height: '20px',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                fontFamily: 'unset',
                fontSize: '18px',
                color: '#000646',
              }}
            >
              {footerContent.contactText}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                gap: 1,
                opacity: '0.85',
                mt: 2,
              }}
            >
              <LocalPhoneIcon sx={{ color: '#000646' }} />
              <Typography sx={{ color: '#000646' }}>
                {footerContent.contact.phone}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                gap: 1,
                opacity: '0.85',
                mt: 2,
              }}
            >
              <EmailIcon sx={{ color: '#000646' }} />
              <Typography sx={{ color: '#000646' }}>
                {footerContent.contact.email}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                gap: 1,
                opacity: '0.85',
                mt: 2,
              }}
            >
              <LanguageIcon sx={{ color: '#000646' }} />
              <Typography sx={{ color: '#000646' }}>
                {footerContent.contact.website}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography
            variant="body"
            sx={{
              fontWeight: 100,
            }}
          >
            {footerContent.copyright}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
