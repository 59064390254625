import { useState } from 'react';
import useLanguage from '../providers/useLanguage';

const PostContactForm = async (values, successCallback, errorCallback) => {
  await fetch('https://gerovconsult.com/api/send', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({ values }),
  })
    .then((res) => res.json())
    .then(async (res) => {
      const resData = await res;
      if (resData.status === 'success') {
        successCallback();
      } else if (resData.status === 'fail') {
        errorCallback();
      }
    });
};

const initialFormValues = {
  name: '',
  phone: '',
  email: '',
  message: '',
  formSubmitted: false,
  success: false,
};

export const useFormControls = () => {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});

  const {
    language: { contact: content },
  } = useLanguage();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ('name' in fieldValues)
      temp.name = fieldValues.name ? '' : content.required;

    if ('phone' in fieldValues)
      temp.phone = fieldValues.phone ? '' : content.required;

    if ('email' in fieldValues) {
      temp.email = fieldValues.email ? '' : content.required;
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ''
          : content.emailFormat;
    }

    if ('message' in fieldValues)
      temp.message = fieldValues.message.length !== 0 ? '' : content.required;

    setErrors({
      ...temp,
    });
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSuccess = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: true,
    });
  };

  const handleError = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: false,
    });
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.name &&
      fieldValues.email &&
      fieldValues.message &&
      Object.values(errors).every((x) => x === '');

    return isValid;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const isValid =
      Object.values(errors).every((x) => x === '') && formIsValid();
    if (isValid) {
      await PostContactForm(values, handleSuccess, handleError);
    }
  };

  return {
    values,
    errors,
    handleInputValue,
    handleFormSubmit,
    formIsValid,
  };
};
