import { Link, Button, Typography } from '@mui/material';

export default function NavLinkButton({
  title,
  to,
  idx,
  isMobile = false,
  isFooter = false,
  handleClick,
}) {
  return isMobile ? (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Button
        key={idx}
        onClick={() => handleClick(to)}
        sx={{
          display: 'block',
          color: '#000646',
          textTransform: 'none',
          fontFamily: 'unset',
          fontSize: '18px',
          fontWeight: 500,
          ':hover': {
            color: '#6062ff',
            background: 'none',
          },
        }}
      >
        {title}
      </Button>
    </Link>
  ) : (
    <Typography
      variant="h6"
      onClick={() => handleClick(to)}
      sx={{
        textTransform: 'none',
        color: '#000646',
        mr: 3,
        display: 'inline-block',
        fontFamily: 'unset',
        fontSize: '16px',
        fontWeight: isFooter ? 100 : 500,
        cursor: 'pointer',
        ':hover': {
          background: 'none',
          color: '#6062ff',
        },
      }}
    >
      {title}
    </Typography>
  );
}
