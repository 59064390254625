import { Container, Box, Typography } from '@mui/material';

import svg1 from '../assets/services/1.svg';
import svg2 from '../assets/services/4.svg';
import svg3 from '../assets/services/6.svg';
import svg4 from '../assets/services/5.svg';

import ServiceCard from '../components/ServiceCard';

import useLanguage from '../providers/useLanguage';

export default function Services({ props }) {
  const {
    language: { services: content },
  } = useLanguage();

  return (
    <Container maxWidth="lg" id="services">
      <Box
        sx={{
          mt: 8,
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: 'center',
            fontWeight: 700,
            width: '100%',
          }}
        >
          {content.heading}
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            gap: 3,
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <ServiceCard
            to="civil-law"
            svg={svg1}
            text={content.services[0].heading}
            description={content.services[0].description}
          />
          <ServiceCard
            to="contract-law"
            svg={svg2}
            text={content.services[1].heading}
            description={content.services[1].description}
          />
          <ServiceCard
            to="commercial-law"
            svg={svg3}
            text={content.services[2].heading}
            description={content.services[2].description}
          />

          <ServiceCard
            to="property-law"
            svg={svg4}
            text={content.services[3].heading}
            description={content.services[3].description}
          />
        </Box>
      </Box>
    </Container>
  );
}
