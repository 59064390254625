import img1 from '../assets/banner/1.jpg';
import img2 from '../assets/banner/2.jpg';
import img3 from '../assets/banner/3.jpg';
import img4 from '../assets/banner/4.jpg';
import img5 from '../assets/banner/5.jpg';

export default function BannerSvgs({ isMobile }) {
  return (
    <>
      <img
        style={{
          position: 'absolute',
          ...(!isMobile ? { left: '0', top: '-6%' } : { left: 0, top: '-5%' }),
          ...(isMobile && { height: '30px', width: '30px' }),
          zIndex: -1,
        }}
        src={img1}
        alt="Drawing 1"
      />
      <img
        style={{
          position: 'absolute',
          ...(isMobile && { height: '30px', width: '30px' }),
          left: '0',
          top: '35%',
          zIndex: -1,
        }}
        src={img2}
        alt="Drawing 2"
      />
      {!isMobile && (
        <img
          style={{
            position: 'absolute',
            left: '8%',
            bottom: '0',
            zIndex: -1,
            ...(isMobile && { height: '30px', width: '30px' }),
          }}
          src={img3}
          alt="Drawing 3"
        />
      )}
      <img
        style={{
          position: 'absolute',
          ...(!isMobile
            ? { bottom: '-6%', right: '7%' }
            : { bottom: '28%', right: '7%' }),
          ...(isMobile && { height: '35px', width: '35px' }),
          zIndex: -1,
        }}
        src={img4}
        alt="Drawing 4"
      />
      <img
        style={{
          position: 'absolute',
          ...(!isMobile
            ? { right: '2%', top: '10%' }
            : { right: '2%', top: '30%' }),
          ...(isMobile && { height: '25px', width: '25px' }),
          zIndex: -1,
        }}
        src={img5}
        alt="Drawing 5"
      />
    </>
  );
}
