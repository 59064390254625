import {
  Container,
  Box,
  Button,
  TextField,
  Typography,
  Alert,
} from '@mui/material';
import { useFormControls } from '../controllers/ContactFormController';
import useLanguage from '../providers/useLanguage';
export default function Contact({ props }) {
  const {
    language: { contact: content },
  } = useLanguage();

  const { values, handleInputValue, handleFormSubmit, formIsValid, errors } =
    useFormControls();

  const inputFieldValues = [
    {
      name: 'name',
      label: content.name,
      id: 'name',
    },
    {
      name: 'email',
      label: content.email,
      id: 'email',
    },
    {
      name: 'phone',
      label: content.phone,
      id: 'phone',
    },
    {
      name: 'message',
      label: content.message,
      id: 'message',
      multiline: true,
      rows: 5,
    },
  ];

  return (
    <Box className="wrapper-two" id="contact">
      <Container maxWidth="lg" sx={{ py: 5 }}>
        <Typography
          variant="h2"
          sx={{
            color: 'white',
            mb: 5,
          }}
        >
          {content.heading}
        </Typography>
        <form
          autoComplete="off"
          onSubmit={handleFormSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {inputFieldValues.map((inputFieldValue, index) => {
            return (
              <TextField
                key={index}
                sx={{
                  maxWidth:
                    index + 1 !== inputFieldValues.length ? 'unset' : 'unset',
                  '& .Mui-error': {
                    color: '#f78f93 !important',
                  },
                }}
                color="primary"
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    borderRadius: '10px',
                    color: '#000646',
                    background: 'white',
                    fontFamily: 'unset',
                  },
                }}
                onChange={handleInputValue}
                onBlur={handleInputValue}
                name={inputFieldValue.name}
                label={inputFieldValue.label}
                error={!!errors[inputFieldValue.name]}
                multiline={inputFieldValue.multiline ?? false}
                rows={inputFieldValue.rows ?? 1}
                autoComplete="none"
                {...(errors[inputFieldValue.name] && {
                  error: true,
                  helperText: errors[inputFieldValue.name],
                })}
              />
            );
          })}
          <Button
            key="button"
            variant="outlined"
            type="submit"
            disabled={!formIsValid()}
            sx={{
              width: '200px',
              height: '50px',
              background: 'white',
              borderRadius: '10px',
              fontWeight: 500,
              textTransform: 'none',
              fontFamily: 'unset',
              padding: '10px 30px 10px',
              fontSize: '15px',
              ':hover': {
                background: '#adaeff',
                color: 'white',
              },
            }}
          >
            {content.send}
          </Button>
          {values.formSubmitted === true && values.success === true ? (
            <Alert key="error" severity="success" color="success">
              {content.success}
            </Alert>
          ) : null}
        </form>
      </Container>
    </Box>
  );
}
