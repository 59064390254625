/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import {
  useMediaQuery,
  useTheme,
  AppBar,
  Box,
  Toolbar,
  Container,
  Button,
  Drawer,
  IconButton,
  Icon,
  Typography,
  Divider,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useLanguage from '../providers/useLanguage';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import NavLinkButton from '../components/NavLinkButton';

import useScrollingUp from '../hooks/useScrollingUp';

import logo from '../assets/logo.png';
import { scrollTo } from '../util/scrollTo';

const NavBar = () => {
  const {
    language: { navigation: content },
  } = useLanguage();

  const pages = [
    {
      title: content.home,
      to: 'start',
    },
    {
      title: content.services,
      to: 'services',
    },
    {
      title: content.aboutMe,
      to: 'about-me',
    },
    {
      title: content.reviews,
      to: 'reviews',
    },
    {
      title: content.contact,
      to: 'contact',
    },
  ];

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const scrolled = useScrollingUp();

  const handleClick = (id) => {
    scrollTo({ id });
  };

  return (
    <>
      <AppBar
        position={scrolled ? 'sticky' : 'static'}
        sx={{
          background: '#fff',
          pt: 2,
          boxShadow: scrolled ? '0px 5px 15px rgb(0 6 70 / 4%)' : 'none',
          transition: 'padding .3s ease-in-out',
          '&.MuiAppBar-positionSticky': {
            pt: 2,
          },
        }}
      >
        <Container maxWidth="lg" sx={{}}>
          <Toolbar disableGutters sx={{ margin: 0, padding: 0 }}>
            {isMobile ? (
              <DrawerComponent pages={pages} content={content} />
            ) : (
              <>
                <Box component="img" src={logo} sx={{ height: '40px' }} />
                <Box
                  sx={{
                    justifyContent: 'center',
                    ml: 5,
                    flexGrow: 1,
                    display: { xs: 'none', md: 'flex' },
                  }}
                >
                  {pages.map(({ title, to }, idx) => (
                    <NavLinkButton
                      handleClick={handleClick}
                      key={idx}
                      title={title}
                      to={to}
                      idx={idx}
                    />
                  ))}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <a
                    href={`tel:+359876700966`}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Icon
                        component={PhoneInTalkIcon}
                        sx={{
                          color: '#6062ff',
                          fontSize: '20px',
                        }}
                      />
                      <Typography
                        color="#6062ff"
                        variant="h6"
                        sx={{
                          fontSize: '18px',
                          fontWeight: 500,
                          fontFamily: 'unset',
                        }}
                      >
                        {content.phone}
                      </Typography>
                    </Box>
                  </a>
                  <Button
                    onClick={() => handleClick('contact')}
                    sx={{
                      background: '#6062ff',
                      padding: '10px 30px 10px',
                      textTransform: 'unset',
                      fontFamily: 'unset',
                      borderRadius: '2em',
                      fontSize: '15px',
                      boxShadow: 0,
                      ':hover': { background: '#adaeff' },
                    }}
                    variant="contained"
                  >
                    {content.callToAction}
                  </Button>
                </Box>
              </>
            )}
          </Toolbar>
          <Divider
            sx={{
              pt: scrolled ? 1 : 2,
              borderBottom: scrolled ? 'none' : '1px solid rgb(229, 232, 242)',
              transition: 'padding .3s ease-in-out',
              dropShadow: scrolled ? '2' : 'none',
            }}
          />
        </Container>
      </AppBar>
    </>
  );
};

const DrawerComponent = ({ pages, content }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (id) => {
    scrollTo({ id });
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'no-wrap',
        width: '100%',
        alignItems: 'center',
        px: 1,
      }}
    >
      <Box>
        <a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <Box component="img" src={logo} sx={{ height: '40px' }} />
        </a>
      </Box>
      <Box
        sx={{
          alignSelf: 'center',
        }}
      >
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            sx: {
              display: 'flex',
              width: '80%',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box sx={{ justifyContent: 'center', p: 1 }}>
            <IconButton onClick={() => setOpen(false)} sx={{ mb: 3 }}>
              <Icon
                component={CloseIcon}
                sx={{
                  alignItems: 'left',
                  color: '#070707',
                  fontSize: '35px',
                }}
              />
            </IconButton>
            {pages.map(({ title, to }, idx) => (
              <NavLinkButton
                key={idx}
                title={title}
                to={to}
                idx={idx}
                isMobile
                handleClick={handleClick}
              />
            ))}
          </Box>
          <Box sx={{ p: 2, justifyContent: 'flex-end' }}>
            <Box sx={{ mb: 2 }}>
              <a
                href="tel:+359876700966"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Icon
                    component={PhoneInTalkIcon}
                    sx={{
                      color: '#6062ff',
                      fontSize: '20px',
                    }}
                  />
                  <Typography
                    color="#6062ff"
                    variant="h6"
                    sx={{
                      fontSize: '18px',
                      fontWeight: 500,
                      fontFamily: 'unset',
                    }}
                  >
                    {content.phone}
                  </Typography>
                </Box>
              </a>
            </Box>
            <Button
              onClick={() => handleClick('contact')}
              sx={{
                background: '#6062ff',
                padding: '10px 30px 10px',
                textTransform: 'unset',
                fontFamily: 'unset',
                borderRadius: '2em',
                fontSize: '15px',
                boxShadow: 0,
                ':hover': { background: '#adaeff' },
              }}
              variant="contained"
            >
              {content.callToAction}
            </Button>
          </Box>
        </Drawer>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <a
            href="tel:+359876700966"
            style={{
              textDecoration: 'none',
              marginTop: '1px',
            }}
          >
            <Icon
              component={PhoneInTalkIcon}
              sx={{
                color: '#6062ff',
                fontSize: '30px',
              }}
            />
          </a>
          <IconButton onClick={() => setOpen(!open)}>
            <Icon
              component={MenuIcon}
              sx={{
                color: '#070707',
                fontSize: '40px',
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default NavBar;
