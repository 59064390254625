import { Container, Box, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import useLanguage from '../providers/useLanguage';

const desktop = {
  position: 'relative',
  py: 4,
  maxWidth: '400px',
  borderTop: '1px solid #23e5af',
  '&::after': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    top: '-15px',
    right: '-31px',
    width: '10px',
    height: '10px',
    border: '10px solid #074c70',
    borderRadius: '15px',
    backgroundColor: '#23e5af',
  },
};

const mobile = {
  position: 'relative',
  px: 4,
  maxWidth: '400px',
  borderLeft: '1px solid #23e5af',
  '&::after': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    bottom: '-30px',
    left: '-16px',
    width: '10px',
    height: '10px',
    border: '10px solid #074c70',
    borderRadius: '15px',
    backgroundColor: '#23e5af',
  },
};

function Step({ text }) {
  return (
    <Box sx={{ ...(isMobile ? mobile : desktop) }}>
      <Typography variant="body" sx={{ color: 'white' }}>
        {text}
      </Typography>
    </Box>
  );
}

export default function Promote({ props }) {
  const {
    language: { steps: content },
  } = useLanguage();
  const steps = [content.step1, content.step2, content.step3];

  return (
    <Box className="wrapper-three" sx={{ mt: 12 }}>
      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h5" sx={{ color: 'white', fontWeight: 700 }}>
          {content.heading}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: isMobile ? 'wrap' : 'no-wrap',
            gap: 4,
            mt: 5,
            py: 4,
            px: 4,
          }}
        >
          {steps.map((step, index) => (
            <Step key={index} text={step} />
          ))}
        </Box>
      </Container>
    </Box>
  );
}
