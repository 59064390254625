import { Box, Container, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';

import BannerSvgs from '../components/BannerSvgs';
import Button from '../components/Button';
import OutlinedButton from '../components/OutlinedButton';
import RotatingText from '../components/RotatingText';
import useLanguage from '../providers/useLanguage';
import { scrollTo } from '../util/scrollTo';

export default function Banner() {
  const {
    language: { banner: content },
  } = useLanguage();

  return (
    <Container maxWidth="lg" id="start">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          mt: isMobile ? 5 : 15,
          color: '#000646',
          position: 'relative',
        }}
      >
        <BannerSvgs isMobile={isMobile} />

        {isMobile ? (
          <Box sx={{ mt: 2, display: 'block', height: '150px' }}>
            <Typography variant="h1">{content.heading}</Typography>
            <RotatingText
              items={[
                content.rotatingText.typography1,
                content.rotatingText.typography2,
                content.rotatingText.typography3,
              ]}
              isMobile
            />
          </Box>
        ) : (
          <>
            <Typography variant="h1">{content.heading}</Typography>
            <RotatingText
              items={[
                content.rotatingText.typography1,
                content.rotatingText.typography2,
                content.rotatingText.typography3,
              ]}
            />
          </>
        )}

        <Box sx={{ mt: 3, maxWidth: '650px' }}>
          <Typography variant="subheading">{content.description}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mt: 5,
            ...(isMobile
              ? { width: '100%', flexDirection: 'column' }
              : { flexDirection: 'row' }),
          }}
        >
          <Button
            text={content.buttons.primary}
            fullWidth={isMobile}
            onClick={() => scrollTo({ id: 'contact' })}
          />
          <OutlinedButton
            text={content.buttons.secondary}
            fullWidth={isMobile}
            onClick={() => scrollTo({ id: 'about-me' })}
          />
        </Box>
      </Box>
    </Container>
  );
}
