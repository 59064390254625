import { Box, Container, Typography } from '@mui/material';
import me from '../assets/ivan_gerov.jpeg';
import quotes from '../assets/about-me/quotes.svg';
import { isMobile } from 'react-device-detect';
import useLanguage from '../providers/useLanguage';

export default function About({ props }) {
  const {
    language: { about: content },
  } = useLanguage();

  return (
    <Box sx={{ mt: 12 }} id="about-me">
      <Container
        className="about-me-container"
        maxWidth="lg"
        style={{ padding: isMobile ? '30px 30px' : '75px 75px' }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          background: 'white',
          boxShadow: '0 120px 120px 0 rgb(5 22 70 / 6%)',
          gap: 10,
          flexWrap: 'wrap',
        }}
      >
        <div style={{ position: 'relative' }}>
          <div className="pattern-overlay"></div>
          <div style={{ postion: 'relative', width: '320px', height: '380px' }}>
            <Box
              component="img"
              src={me}
              sx={{
                position: 'absolute',
                zIndex: 2,
                height: '380px',
                objectFit: 'cover',
                width: '320px',
                objectPosition: 'center top',
              }}
            />
          </div>
        </div>
        <Box
          sx={{ maxWidth: '600px', display: 'flex', flexDirection: 'column' }}
        >
          <Box
            component="img"
            src={quotes}
            sx={{ width: '25px', height: '25px' }}
          />
          <Typography variant="body" sx={{ fontSize: '20px', mt: 4 }}>
            {content.description}
          </Typography>
          <Typography variant="body" sx={{ fontSize: '20px', mt: 4 }}>
            {content.name}
          </Typography>
          <Typography
            variant="body"
            sx={{ fontSize: '20px', color: '#23e5af', mt: 1 }}
          >
            {content.title}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
