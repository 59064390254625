import { Button } from '@mui/material';

export default function OutlinedButton({
  text,
  type = 'submit',
  fullWidth,
  ...others
}) {
  return (
    <Button
      color="primary"
      type={type}
      sx={{
        background: 'transparent',
        padding: '8px 30px 8px',
        textTransform: 'unset',
        fontFamily: 'unset',
        color: '#6062ff',
        border: '2px solid #6062ff',
        borderRadius: '2em',
        fontSize: '15px',
        boxShadow: 0,
        ':hover': { background: '#6062ff', color: 'white' },
        ...(fullWidth && { width: '100%' }),
      }}
      {...others}
    >
      {text}
    </Button>
  );
}
