import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#6062ff',
      darker: '#ffffff',
    },
    contactForm: {
      main: 'white',
      darker: 'white',
    },
  },
});

// Typography

theme.typography.logo = {
  ...theme.typography.h5,
  color: '#000646',
  fontWeight: 700,
};

theme.typography.h1 = {
  margin: 0,
  fontFamily: 'unset',
  color: '#000646',
  fontSize: '3.75rem',
  fontWeight: '700',
  [theme.breakpoints.up('xs')]: {
    fontSize: '2.25rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.75rem',
  },
};

theme.typography.h2 = {
  margin: 0,
  fontFamily: 'unset',
  color: '#000646',
  fontSize: '2.25rem',
  fontWeight: '700',
  [theme.breakpoints.up('xs')]: {},
  [theme.breakpoints.up('lg')]: {},
};

theme.typography.subheading = {
  ...theme.typography.body,
  fontFamily: 'unset',
  fontSize: '19px',
  fontWeight: 400,
  color: '#000646',
};

theme.typography.body = {
  fontFamily: 'unset',
};

export default theme;
