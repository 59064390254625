import { Button as MuiButton } from '@mui/material';

export default function ButtonButton({ text, fullWidth, ...others }) {
  return (
    <MuiButton
      sx={{
        background: '#6062ff',
        padding: '10px 30px 10px',
        textTransform: 'unset',
        fontFamily: 'unset',
        borderRadius: '2em',
        fontSize: '15px',
        boxShadow: 0,
        ':hover': { background: '#adaeff' },
        ...(fullWidth && { width: '100%' }),
      }}
      variant="contained"
      {...others}
    >
      {text}
    </MuiButton>
  );
}
