import { isMobile } from 'react-device-detect';
import { Card as MuiCard, Icon, Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

export default function Card({ svg, name, review }) {
  return (
    <MuiCard
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        zIndex: 3,
        width: isMobile ? '100%' : '280px',
        padding: '2.4em 2em',
        gap: 2,
        boxShadow: '0 50px 89px 0 rgb(0 6 70 / 3%)',
        borderRadius: '2px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Box
          component="img"
          src={svg}
          alt={`${name}-review`}
          sx={{ height: 70, width: 70, borderRadius: 10, objectFit: 'cover' }}
        />
      </Box>
      <Typography
        variant="h3"
        sx={{
          fontWeight: 700,
          fontFamily: 'unset',
          fontSize: '32px',
          color: '#000646',
        }}
      >
        {name}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Icon component={StarIcon} sx={{ color: '#FFDF00' }} />
        <Icon component={StarIcon} sx={{ color: '#FFDF00' }} />
        <Icon component={StarIcon} sx={{ color: '#FFDF00' }} />
        <Icon component={StarIcon} sx={{ color: '#FFDF00' }} />
        <Icon component={StarIcon} sx={{ color: '#FFDF00' }} />
      </Box>
      <Typography variant="body" sx={{ fontFamily: 'unset' }}>
        {review}
      </Typography>
    </MuiCard>
  );
}
