import { Container, Box } from '@mui/material';
import Card from '../components/Card';

import nina from '../assets/reviews/nina.jpg';
import martin from '../assets/reviews/martin.jpg';
import maria from '../assets/reviews/maria.jpg';
import useLanguage from '../providers/useLanguage';

export default function Reviews({ props }) {
  const {
    language: { reviews: content },
  } = useLanguage();
  return (
    <Box id="reviews" sx={{ background: '#f9fbfe' }}>
      <Container
        maxWidth="lg"
        className="wrapper"
        sx={{
          display: 'flex',
          width: '100%',
          mt: 15,
          py: 6,
          gap: 4,
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <Card svg={nina} name={content[0].name} review={content[0].review} />
        <Card svg={martin} name={content[1].name} review={content[1].review} />
        <Card svg={maria} name={content[2].name} review={content[2].review} />
      </Container>
    </Box>
  );
}
