const logError = () =>
  console.error(
    `Invalid element, are you sure you've provided element id or react ref?`
  );

export const scrollTo = ({ id }) => {
  const element = document.getElementById(id);

  if (!element) {
    logError();
    return;
  }

  const y = element.getBoundingClientRect().top + window.pageYOffset - 100;

  window.scrollTo({ top: y, behavior: 'smooth' });
};
