import NavBar from './sections/Navbar';

const App = ({ children }) => {
  return (
    <>
      <NavBar />
      {children}
    </>
  );
};
export default App;
