import About from '../sections/About';
import Banner from '../sections/Banner';
import Contact from '../sections/Contact';
import Footer from '../sections/Footer';
import Promote from '../sections/Promote';
import Reviews from '../sections/Reviews';
import Services from '../sections/Services';
import ServicesMore from '../sections/ServicesMore';

const Home = () => {
  return (
    <>
      <Banner />
      <Services />
      <About />
      <Reviews />
      <ServicesMore />
      <Promote />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
