import { Container } from '@mui/material';

import civilLaw from '../assets/services/civil-law.svg';
import contractLaw from '../assets/services/contract-law.svg';
import commercialLaw from '../assets/services/commercial-law.svg';
import propertyLaw from '../assets/services/property-law.svg';

import ImageWithText from '../components/ImageWithText';
import useLanguage from '../providers/useLanguage';

export default function ServicesMore({ props }) {
  const {
    language: { servicesExplained: content },
  } = useLanguage();
  const services = [
    {
      id: 'civil-law',
      title: content[0].heading,
      description: content[0].description,
      img: civilLaw,
      services: content[0].services,
      reverse: false,
    },
    {
      id: 'contract-law',
      title: content[1].heading,
      description: content[1].description,
      img: contractLaw,
      services: content[1].services,
      reverse: true,
    },
    {
      id: 'commercial-law',
      title: content[2].heading,
      description: content[2].description,
      img: commercialLaw,
      services: content[2].services,
      reverse: false,
    },
    {
      id: 'property-law',
      title: content[3].heading,
      description: content[3].description,
      img: propertyLaw,
      services: content[3].services,
      reverse: true,
    },
  ];
  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      {services.map((service, idx) => (
        <ImageWithText key={idx} {...service} />
      ))}
    </Container>
  );
}
